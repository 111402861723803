import React from "react";
import brennemoen from "./brennemoen.jpg";
import "./Overnatting.css";

const Overnatting = () => {
  return (
    <div className="overnatting">
      <h1>
        Overnatting
        <span role="img" aria-label="hammer emoji">
          🏩
        </span>
      </h1>
      <div className="scandic-info">
        <h2>Scandic Brennemoen</h2>
        <div>
          <img
            src={brennemoen}
            alt="bilde av hotellet Scandic Brennemoen"
            className="scandic-bilde"
          />
        </div>
        <p style={{ marginTop: "1rem" }}>
          Vi har holdt av nok rom på Scandic Brennemoen
          til alle gjester til rabattert pris (15-20%). Booking av rom må gjøres per telefon (64 87 90 00) eller mail (brennemoen@scandichotels.com), ettersom hotellet fremstår som fullbooket på nettsiden deres. Oppgi Nicolai Hagen som referanse når dere bestiller. 
        </p>
        <p>
          Hotellrommene som er
          holdt av må <b>bookes selv innen 1. juni.</b>
        </p>
        <p>
          I bil ligger hotellet 7 minutter fra Fusk og 2 minutter fra Brødremoen
          (1500m gange). Siden hotellet ligger midt i turisthovedstaden IØ vil
          vi bli overrasket om hotellet blir fullbooket, men vi vil likevel
          anbefale å booke for å sikre innendørs overnatting.
        </p>
      </div>
    </div>
  );
};

export default Overnatting;
