import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCiyeN0V0rFWKEMGn8dl_PlnunV-lG7kGQ",
  authDomain: "bryllupsdb.firebaseapp.com",
  databaseURL: "https://bryllupsdb-default-rtdb.firebaseio.com",
  projectId: "bryllupsdb",
  storageBucket: "bryllupsdb.appspot.com",
  messagingSenderId: "726186335985",
  appId: "1:726186335985:web:060c308fb18373188dd350",
  measurementId: "G-QNQ4G3XNEE",
};

const app = initializeApp(firebaseConfig);

const firestoreDb = getFirestore();

const firebaseStorage = getStorage(app);

export { firestoreDb, firebaseStorage };
