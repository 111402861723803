import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import "./Bordplassering.css";
import { bordplasseringer } from "./bordplasseringer";

const finnesGjestPåBord = (bord, søkeord) => {
  if (!søkeord || søkeord.length < 2) {
    return true;
  }

  return bord.gjester
    .map((gjest) => gjest.navn.toLowerCase())
    .join()
    .includes(søkeord.toLowerCase());
};

const stringifyGjesterPåBord = (bord, søkeord) => {
  return bord.gjester.map((gjest) => gjest.navn.split(" ")[0]).join(", ");
};

const splittOppStreng = (langNavneStreng, søkeord) => {
  const langNavneStrengLowercase = langNavneStreng.toLowerCase();
  const indexOfSøkeord = langNavneStrengLowercase.indexOf(
    søkeord.toLowerCase()
  );
  return {
    sluttenAvFørsteDel: indexOfSøkeord - 1 <= -1 ? 0 : indexOfSøkeord - 1,
    sluttenAvSøkeord: indexOfSøkeord >= 0 ? indexOfSøkeord + søkeord.length : 0,
    slutt: langNavneStrengLowercase.length,
  };
};

const Bordplassering = () => {
  const [søkeTekst, setSøkeTekst] = useState("");

  const antallBordFunnet = bordplasseringer.filter((bord) =>
    finnesGjestPåBord(bord, søkeTekst)
  ).length;

  const optionalProps = {};
  if (antallBordFunnet === 1) {
    optionalProps.defaultActiveKey = "0";
    optionalProps.alwaysOpen = true;
  }

  return (
    <div>
      <h1>
        Bordplassering
        <span role="img" aria-label="hammer emoji">
          🪑
        </span>
      </h1>
      <p className="bordplassering-info">Søk opp navnet ditt:</p>
      <div className="sokefelt-wrapper">
        <TextField
          id="sokefelt"
          variant="outlined"
          fullWidth
          onChange={(e) => setSøkeTekst(e.target.value)}
          label="Ditt navn"
        />
      </div>
      {antallBordFunnet > 0 && (
        <>
          {søkeTekst === "" && (
            <p className="bordplassering-info" style={{ marginTop: "1rem" }}>
              Eller kikk i listen:
            </p>
          )}

          <Accordion
            className="bordplassering-accordion"
            defaultActiveKey={["0"]}
            {...optionalProps}
          >
            {bordplasseringer
              .filter((bord) => finnesGjestPåBord(bord, søkeTekst))
              .map((bord, index) => {
                const navneStrengKomplett = stringifyGjesterPåBord(
                  bord,
                  søkeTekst
                );
                const navneStreng = splittOppStreng(
                  stringifyGjesterPåBord(bord, søkeTekst),
                  søkeTekst
                );

                const SattSammen = () => (
                  <>
                    <span>
                      {navneStrengKomplett.substring(
                        0,
                        navneStreng.sluttenAvFørsteDel
                      )}
                    </span>
                    <span style={{ fontWeight: "900" }}>
                      {navneStrengKomplett.substring(
                        navneStreng.sluttenAvFørsteDel,
                        navneStreng.sluttenAvSøkeord
                      )}
                      &nbsp;
                    </span>
                    <span>
                      {navneStrengKomplett.substring(
                        navneStreng.sluttenAvSøkeord,
                        navneStreng.slutt
                      )}
                    </span>
                  </>
                );

                return (
                  <Accordion.Item
                    eventKey={index}
                    key={index}
                    className="bordplassering-accordion-item"
                  >
                    <Accordion.Header>
                      {`${bord.nr}:  `}
                      <SattSammen />
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul style={{ padding: "0" }}>
                        {bord.gjester.map((gjest, idx) => (
                          <li key={idx} style={{ display: "block" }}>
                            {" "}
                            {gjest.navn}
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </>
      )}
      {!antallBordFunnet && (
        <p className="bordplassering-info" style={{ marginTop: "2rem" }}>
          Dessverre, ingen funn!
        </p>
      )}
    </div>
  );
};

export default Bordplassering;
