export const bordplasseringer = [];
/* export const bordplasseringer = [
  {
    nr: 1,
    gjester: [
      { navn: "Vigdis Brødremoen" },
      { navn: "Line Brødremoen Brevig" },
      { navn: "Nicolai August Hagen" },
      { navn: "Claus Albro Hagen" },
      { navn: "Ola Brevig" },
      { navn: "Gro Helene Hagen" },
    ],
  },
  {
    nr: 2,
    gjester: [
      { navn: "Mikkel Sandboe" },
      { navn: "Fredrik Notøy" },
      { navn: "André Callil" },
      { navn: "Anna Hernes" },
      { navn: "Magne Orgland" },
      { navn: "Eivind Olavson Straume" },
      { navn: "Marthe Guren" },
      { navn: "Hilde Aas" },
    ],
  },
  {
    nr: 3,
    gjester: [
      { navn: "Anne Borch" },
      { navn: "Hans Jacob Kavli" },
      { navn: "Hanne Løken" },
      { navn: "Philip Myre Aamlid" },
      { navn: "Silje Nilo" },
      { navn: "Reidar Nævdal" },
      { navn: "Sara Dahlen" },
      { navn: "Ludvig Løken" },
    ],
  },
  {
    nr: 4,
    gjester: [
      { navn: "Arnfinn Løken" },
      { navn: "Lise Rognerud Løken" },
      { navn: "Petter Dillevig" },
      { navn: "Marit Brødremoen" },
      { navn: "Anne Hagen" },
      { navn: "Totto Hagen" },
      { navn: "Anne-Ma Brevig" },
      { navn: "Terje Olsson" },
      { navn: "Rosita Olsson" },
    ],
  },
  {
    nr: 5,
    gjester: [
      { navn: "Gro Elisabeth Brastad" },
      { navn: "Anders Sølvberg" },
      { navn: "Ole Kristian Brødremoen" },
      { navn: "Kirsten Brødremoen" },
      { navn: "Kristin Oddny Elgsaas" },
      { navn: "Jan Barkerud" },
      { navn: "Åshild Brødremoen" },
      { navn: "Cecilie Hagen" },
    ],
  },
  {
    nr: 6,
    gjester: [
      { navn: "Jonas Nouri" },
      { navn: "Tilde Midtvedt" },
      { navn: "Ingrid Tønnnessen Bergstøl" },
      { navn: "Harald Morseth" },
      { navn: "Huong Huynh" },
      { navn: "Kristian Taylor" },
      { navn: "Lars Mikkel Reiersen" },
      { navn: "Astri Marie Ravnaas" },
    ],
  },
  {
    nr: 7,
    gjester: [
      { navn: "Martin Røe" },
      { navn: "Thomas Lintho" },
      { navn: "Emilie Brødremoen Lund" },
      { navn: "Håkon Sylliaas" },
      { navn: "Carina Stenerud Tveter" },
      { navn: "Lena Jansson" },
      { navn: "Sondre Bilet" },
      { navn: "Tonje Vestad Nygård" },
    ],
  },
  {
    nr: 8,
    gjester: [
      { navn: "Herman Dillevig" },
      { navn: "Nora Bjerkli" },
      { navn: "Emma D. Brødremoen" },
      { navn: "Julie Muren" },
      { navn: "Jenny Brødremoen Brevig" },
      { navn: "Christoffer Lysenstøen" },
    ],
  },
  {
    nr: 9,
    gjester: [
      { navn: "Andreas Berg Aslaksen" },
      { navn: "Vetle Larsen" },
      { navn: "Kine Bjerke Dalheim" },
      { navn: "Mikkel Munthe-Kaas" },
      { navn: "Abby Brobakken" },
      { navn: "Margrete Rønning" },
    ],
  },
  {
    nr: 10,
    gjester: [
      { navn: "Martin Broeng" },
      { navn: "Oda Endresen" },
      { navn: "Stella Bruland Knudsen" },
      { navn: "Markus Rauhut" },
      { navn: "Tina Øen Haugen" },
      { navn: "Conrad Lehne Drangsland" },
    ],
  },
  {
    nr: 11,
    gjester: [
      { navn: "Morten Clifford" },
      { navn: "Lars Brødremoen Brevig" },
      { navn: "Mai Watson" },
      { navn: "Eirik Lund" },
      { navn: "Christina Aaserud" },
      { navn: "Sigurd Rehoorst Lerim" },
      { navn: "Helene Hagen" },
      { navn: "Henrik Sunde" },
    ],
  },
  {
    nr: 12,
    gjester: [
      { navn: "Kristian Rafn Dalene" },
      { navn: "Benedicte Rafn Dalene" },
      { navn: "Louise Heidal" },
      { navn: "Eirik Lysheim" },
      { navn: "Tor Anonsen" },
      { navn: "Miriam Heggøy" },
      { navn: "Laura Tiltnes" },
      { navn: "Tine Lauritzen" },
    ],
  },
];
 */
