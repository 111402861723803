import React, { useState, useEffect } from "react";
import { firebaseStorage } from "../config/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { Button } from "react-bootstrap";

import "./Bildebank.css";

const listRef = ref(firebaseStorage, `/`);

const Bildebank = () => {
  const [alleBildeURLer, setAlleBildeURLer] = useState([]);
  const [knappKlikket, setKnappKlikket] = useState(false);

  useEffect(() => {
    listAll(listRef)
      .then((res) => {
        setAlleBildeURLer(res.items.map((item) => item._location.path));
      })
      .catch((error) => {});
  }, []);

  const hentAlleBilder = () => {
    if (alleBildeURLer && alleBildeURLer.length > 0) {
      alleBildeURLer.map((bildeUrl, idx) => {
        console.log("bildeUrl", bildeUrl);
        getDownloadURL(ref(firebaseStorage, "/" + bildeUrl))
          .then((url) => {
            console.log("url", url);
            // Or inserted into an <img> element
            const img = document.getElementById(`bilde-${idx}`);
            img.setAttribute("src", url);
          })
          .catch((error) => {
            // Handle any errors
          });
        return null;
      });
    }
  };

  return (
    <div style={{ textAlign: "center", marginBottom: "5rem" }}>
      <Button
        type="submit"
        onClick={() => {
          hentAlleBilder();
          setKnappKlikket(true);
        }}
        style={{
          marginTop: "8rem",
          width: "75%",
          backgroundColor: "#fd840d",
        }}
      >
        Hent bilder
      </Button>

      {knappKlikket && (
        <h1>
          <span role="img" aria-label="hammer emoji">
            👇
          </span>{" "}
          Opplastede bilder{" "}
          <span role="img" aria-label="hammer emoji">
            👇
          </span>
        </h1>
      )}
      {alleBildeURLer.map((bildeUrl, idx) => (
        <img
          alt=""
          key={idx}
          id={`bilde-${idx}`}
          style={{
            width: "65%",
            height: "65%",
            marginTop: "2rem",
            borderRadius: "3%",
          }}
        />
      ))}
    </div>
  );
};

export default Bildebank;
