import React from "react";
import "./Bidrag.css";

const Bidrag = () => {
  return (
    <div className="bidrag-site">
      <h1>Taler? Bidrag?</h1>
      <div className="bidrag-info">
        <h2>
          Folkens, toastmaster{" "}
          <span role="img" aria-label="hammer emoji">
            🥷
          </span>
        </h2>

        <h4>Eivind Olavson Straume</h4>
        <p style={{ marginTop: "1rem" }}>
          Eivind er vår sangomsuste toastmaster, og er den eneste personen vi
          kjenner som både har doktorgrad i geologi OG Valkyrien grill{" "}
          <span role="img" aria-label="hammer emoji">
            🪨
          </span>{" "}
          DET BLIR STEINBRA!
        </p>
        <p>
          Eivind er en trivelig fyr. Ta kontakt med Eivind om du har lyst til å
          holde en tale eller liknende i bryllupet.
        </p>
        <p style={{ marginBottom: 0 }}>
          <b>Mail</b>: xxxxxxx.xxxxxxx@gmail.com
        </p>

        <p>
          <b>Telefon</b>: +47 xxx xx xxx
        </p>
        <div style={{ maxWidth: "550px" }}>
          <video width="100%" controls autoPlay playsInline muted loop>
            <source src="eivind.mp4" type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Bidrag;

/*
<img
            src={brennemoen}
            alt="bilde av hotellet Scandic Brennemoen"
            className="scandic-bilde"
          />


          SKAL INN I TOM DIV
*/
