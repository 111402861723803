import React from "react";
import { useFirestore } from "../hooks/useFirestore";
import { Accordion } from "react-bootstrap";
import "./Gjesteliste.css";

const kommerTroligFredag = (navn) => {
  return ["ja", "JA", "ja!", "JA!", "Ja"].indexOf(navn) >= 0;
};

function Gjesteliste() {
  const { docs } = useFirestore("gjesteliste");

  const antallFredag = docs.filter((gjest) => {
    return kommerTroligFredag(gjest["Kommer fredag"]);
  }).length;

  const antallSanger = docs.filter(
    (gjest) => gjest["Kommer fredag"] !== ""
  ).length;
  const antallAllergier = docs.filter(
    (gjest) =>
      gjest["Allergier"] !== "" &&
      gjest["Allergier"] !== "nei" &&
      gjest["Allergier"] !== "Nei" &&
      gjest["Allergier"] !== "Ingen" &&
      gjest["Allergier"] !== "Ingen "
  ).length;

  const sangene = docs
    .filter((gjest) => gjest["Favorittsang"] !== "")
    .map((gjest) => {
      return {
        sang: gjest["Favorittsang"],
        navn: gjest["Navn"],
      };
    });

  const allergiene = docs
    .filter(
      (gjest) =>
        gjest["Allergier"] !== "" &&
        gjest["Allergier"] !== "nei" &&
        gjest["Allergier"] !== "Nei" &&
        gjest["Allergier"] !== "Ingen" &&
        gjest["Allergier"] !== "Ingen "
    )
    .map((gjest) => {
      return {
        allergi: gjest["Allergier"],
        navn: gjest["Navn"],
      };
    });

  console.log(docs);

  return (
    <div className="gjesteliste">
      <h1>Gjesteliste</h1>
      <h2 style={{ textAlign: "left", color: "green", marginTop: "1rem" }}>
        {antallFredag} har meldt sin ankomst på fredagen!
      </h2>
      <h2 style={{ textAlign: "left", color: "green", marginTop: "1rem" }}>
        {docs.length}{" "}
        {docs.length > 1 ? "har meldt sin ankomst på lørdagen!" : "gjest er påmeldt!"}{" "}
      </h2>
      <h2 style={{ textAlign: "left", color: "grey", marginTop: "1rem" }}>
        {antallAllergier} allergier lagt inn (se nederst)
      </h2>
      <Accordion defaultActiveKey="0" className="gjesteliste-accordion">
        {docs
          .sort((gjestA, gjestB) =>
            gjestA["Navn"].localeCompare(gjestB["Navn"])
          )
          .map((gjest, index) => {
            return (
              <Accordion.Item
                eventKey={index}
                key={index}
                className="gjesteliste-accordion-item"
              >
                <Accordion.Header>
                  {`${gjest.Navn} - f: ${
                    kommerTroligFredag(gjest["Kommer fredag"]) ? "👍" : "👎"
                  }`}
                </Accordion.Header>
                <Accordion.Body>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <b>Navn:</b> {gjest["Navn"]}
                    </li>
                    <li>
                      <b>Telefonnummer: </b> {gjest.Telefonnummer}
                    </li>
                    <li>
                      <b>Allergier:</b> {gjest.Allergier}
                    </li>
                    <li>
                      <b>Favorittsang: </b> {gjest.Favorittsang}
                    </li>
                    <li>
                      <b>Kommer fredag:</b> {gjest["Kommer fredag"]}
                    </li>
                    <li>
                      <b>Kommer lørdag:</b> {gjest["Kommer lørdag"]}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
      </Accordion>
      <h2 style={{ marginTop: "2rem" }}>Sanger ({antallSanger})! 😍 </h2>
      <ul>
        {sangene.map((gjest) => (
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.youtube.com/results?search_query=${gjest.sang}`}
            >
              {gjest.sang}
            </a>{" "}
            <p>
              <i>(fra: {gjest.navn})</i>
            </p>
          </li>
        ))}
      </ul>
      <h2>Allergier ({antallAllergier})! 🤮</h2>
      <ul>
        {allergiene.map((gjest) => (
          <li>
            {gjest.allergi}{" "}
            <p>
              <i>(fra: {gjest.navn})</i>
            </p>
          </li>
        ))}
      </ul>
      <h2>Gjesteliste/kontakt på fredagen ({antallFredag})</h2>
      <ul>
        {docs
          .sort((gjestA, gjestB) =>
            gjestA["Navn"].localeCompare(gjestB["Navn"])
          )
          .filter((gjest) => kommerTroligFredag(gjest["Kommer fredag"]))
          .map((gjest) => {
            return (
              <li>
                {gjest["Navn"]} ({gjest["Kommer fredag"]}),{"  "}
                <a
                  style={{ display: "inline-block" }}
                  href={`tel:+47${gjest["Telefonnummer"]}`}
                >
                  {gjest["Telefonnummer"]}
                </a>
              </li>
            );
          })}
      </ul>
      <h2>Gjesteliste/kontakt på lørdagen ({docs.length})</h2>
      <ul>
        {docs
          .sort((gjestA, gjestB) =>
            gjestA["Navn"].localeCompare(gjestB["Navn"])
          )
          .map((gjest) => {
            return (
              <li>
                {gjest["Navn"]},{"  "}
                <a
                  style={{ display: "inline-block" }}
                  href={`tel:+47${gjest["Telefonnummer"]}`}
                >
                  {gjest["Telefonnummer"]}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default Gjesteliste;
