import React from "react";
import "./Byggingen.css";
import bilde1 from "./IMG-2836.jpg";
import bilde2 from "./IMG-3172.jpg";
import bilde3 from "./IMG-3176.jpg";
import bilde4 from "./IMG-5660.jpg";
import bilde5 from "./IMG-5848.jpg";
import bilde6 from "./IMG-6541.jpg";
import bilde7 from "./IMG-6710.jpg";
import bilde8 from "./IMG-6700.jpg";
import bilde9 from "./IMG-6703.jpg";

import mars3 from "./mars-1.jpg";
import mars1 from "./mars-3.jpg";
import mars4 from "./mars-4.jpg";
import mars5 from "./mars-5.jpg";

import paske1 from "./paske-1.jpg";
import paske2 from "./paske-2.jpg";
import paske3 from "./paske-3.jpg";
import paske4 from "./paske-4.jpg";

import mai1_1 from "./1-mai-1.jpg";
import mai1_2 from "./1-mai-2.jpg";
import mai1_3 from "./1-mai-3.jpg";

import mai1 from "./mai1.jpg";
import mai2 from "./mai2.jpg";
import mai3 from "./mai3.jpg";
import mai4 from "./mai4.jpg";
import mai5 from "./mai5.jpg";
import mai6 from "./mai6.jpg";

import jun1 from "./jun1.jpg";
import jun2 from "./jun2.jpg";
import jun3 from "./jun3.jpg";

import line from "./line.jpg";

import jul1 from "./jul1.jpg";
import jul2 from "./jul2.jpg";

import portalbilde from "./portal.jpg";

const Spacer = () => (
  <div style={{ textAlign: "center", fontSize: "50px" }}>⋮</div>
);

function Byggingen() {
  return (
    <div className="bygging">
      <h1>
        Vi bygger om låven{" "}
        <span role="img" aria-label="hammer emoji">
          🔨
        </span>
      </h1>

      <div className="tidspunkt-boks" style={{ marginTop: "2rem auto" }}>
        <h3>17. oktober 2020</h3>
        <img src={bilde1} alt="xx" className="bygge-bilder" />
        <p className="tidspunkt-paragraf">
          <i>Første rekognisering av låven som mulig sted for bryllupet.</i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>28. desember 2020</h3>
        <img src={bilde2} alt="xx" className="bygge-bilder" />
        <img
          src={bilde3}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf">
          <i>
            "Hmm..hva er alle disse redskapene i låverommet?" Nicolai lærer seg
            mye om gårdlivet slik det var i gamle dager.
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>24. oktober 2021</h3>
        <img src={bilde4} alt="xx" className="bygge-bilder" />
        <img
          src={bilde5}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf">
          <i>Etter ryddings startet vi med det viktigste først. Baren!</i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>12. februar 2022</h3>
        <img src={bilde6} alt="xx" className="bygge-bilder" />
        <p className="tidspunkt-paragraf">
          <i>
            Koste, koste, koste. Gammelt høy i etasjen under bordsettingen ekke
            bra for brannfaren.{" "}
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>7-9. mars 2022</h3>
        <img src={bilde7} alt="xx" className="bygge-bilder" />
        <img
          src={bilde8}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <img
          src={bilde9}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf">
          <i>
            Brudens far, Ola, retter ut gulvet og bygger dansegulv med litt
            grovhjelp fra snekker og skruing fra Nicolai!
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>19-20. mars 2022</h3>
        <img src={mars1} alt="xx" className="bygge-bilder" />
        <img
          src={mars4}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <img
          src={mars3}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Ola og Nicolai prøver seg som semi-profesjonelle gulvleggere og
            legger gulv med resirkulerte planker, jekkestropp, skruer, litt
            hammer, brekkjern, cola og hvetebakst!
          </i>
        </p>

        <Spacer />

        <img
          src={mars5}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />

        <p className="tidspunkt-paragraf">
          <i>
            Mens brudgom og svigerpapa holder på inne i låven, er Ole Kristian,
            Kirsten, Vigdis og Line i full vigør utenfor og tar seg av
            våraktiviteter i hagen!
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>11-13. april 2022 (påskeuken)</h3>
        <img src={paske1} alt="xx" className="bygge-bilder" />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Mai og Line lakkerer høyrive-hjulene fra 1850-æraen som skal få nytt
            liv som takpynt!
          </i>
        </p>

        <Spacer />
        <img
          src={paske2}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>En Mai-sluker ble observert rense gulvene.</i>
        </p>

        <Spacer />
        <img
          src={paske3}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>Line pusser ned stolpene. </i>
        </p>
        <Spacer />
        <img
          src={paske4}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf">
          <i>
            Nå som gulvet er på plass har vi endelig fått ryddet unna alt av
            planker.
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>1. mai 2022</h3>
        <img src={mai1_1} alt="xx" className="bygge-bilder" />
        <img
          src={mai1_2}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <img
          src={mai1_3}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf">
          <i>
            Trenger ikke nye bjelker når man heller kan pusse frem de gamle!
            <span role="img" aria-label="hammer emoji">
              ⚙️ 💪
            </span>
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>7. mai 2022</h3>
        <img src={portalbilde} alt="xx" className="bygge-bilder" />
        <p className="tidspunkt-paragraf">
          <i>
            Ola og Vigdis har ordnet med portal og blomster til grusgangen!
            <span role="img" aria-label="hammer emoji">
              🌸
            </span>
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>26. - 29. mai 2022 (Kr. himmelfart)</h3>
        <img
          src={mai1}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Overingeniør Mai Watson høvler ned den litt for buede bardisken med
            det nye gliset fra Coop Obs Bygg.
          </i>
        </p>
        <Spacer />
        <img
          src={mai2}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <Spacer />
        <img
          src={mai3}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>Kjerrehjulene får sitt siste strøk hobbylakk.</i>
        </p>
        <Spacer />
        <img
          src={mai4}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Nicolai pleier sponplategulvet med industristøvsugeren før den skal
            få beskyttende farge. Litt for mye forlengerkabel stopper ikke
            gladgutten!
          </i>
        </p>
        <Spacer />
        <img
          src={mai6}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />

        <img
          src={mai5}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Claus, faren til Nicolai, renser låvebroen for skitt og møkk{" "}
            <span role="img" aria-label="hammer emoji">
              😎
            </span>
          </i>
        </p>
      </div>

      <div className="tidspunkt-boks">
        <h3>25. - 26. juni</h3>
        <img
          src={jun1}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Line er alltid glad når hun kan ta i bruk litt Hey'di hvit!{" "}
            <span role="img" aria-label="hammer emoji">
              💛
            </span>
          </i>
        </p>
        <img
          src={jun2}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>Før og etter-bilde av låvebroen. Tilbake til sin gamle prakt!</i>
        </p>
        <Spacer />
        <img
          src={jun3}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>Dansegulvet får sitt siste strøk med maling!</i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>2. - 3.juli</h3>
        <img
          src={line}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Line har vært en champ med pussemaskinen helgen. (lørdagen fikk
            Nicolai kjøre sin jomfrutur med henger da vi hentet 14 hvite benker
            til vielsen fra en kompis av Lars).{" "}
          </i>
        </p>
      </div>

      <Spacer />

      <div className="tidspunkt-boks">
        <h3>8. - 10.juli</h3>
        <img
          src={jul1}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Tidligere bruder, Gro og Vigdis, tester ut pynten Line har kjøpt inn{" "}
            <span role="img" aria-label="hammer emoji">
              🌸
            </span>
          </i>
        </p>
        <img
          src={jul2}
          alt="xx"
          className="bygge-bilder"
          style={{ marginTop: "2rem" }}
        />
        <p className="tidspunkt-paragraf" style={{ marginBottom: "0" }}>
          <i>
            Ole Kristian har malt vinduene på låven kritthvite, og Ola og Claus
            setter de på plass igjen.
          </i>
        </p>
      </div>

      <Spacer />

      <p style={{ padding: "1rem" }}>
        <div style={{ textAlign: "center" }}>
          <span
            role="img"
            aria-label="hammer emoji"
            style={{ fontSize: "5rem" }}
          >
            👰🤵🌸
          </span>
        </div>
        <i>
          ...og resten er straaks historie! Etter dette ønsker vi gjerne å vise
          dere resultatet som en overraskelse. VI GLEDER OSS!
        </i>
      </p>
    </div>
  );
}

export default Byggingen;
