import React from "react";
import "./Forlovere.css";

import hanne from "./hanne.jpg";
import silje from "./silje.png";
import sara from "./sara.PNG";

import hansi from "./hansi.jpg";
import philip from "./philip.jpg";

const Spacer = () => (
  <div style={{ textAlign: "center", fontSize: "50px" }}>⋮</div>
);

function Forlovere() {
  return (
    <div className="forlovere-hoved">
      <h1 style={{ marginBottom: "1rem" }}>
        Våre forlovere{" "}
        <span role="img" aria-label="tada emoji">
          🎉
        </span>
      </h1>

      <div className="om-forlover" style={{ marginTop: "3rem" }}>
        <h2>Hanne-Margrete Rognerud Løken</h2>
        <div className="bilde-wrapper">
          <img
            id="forlover-bilde"
            src={hanne}
            alt="bilde av Hanne"
            className="forlover-bilder"
          />
        </div>
        <p className="forlover-tekst">
          Tospannet Hanne og Line har kjent hverandre hele sitt voksne og
          barnete liv. De vokste opp med at foreldrene var venner med hverandre,
          og de har også vært naboer, som på bygda vil si 1,5 kilometer unna
          hverandre.
        </p>
      </div>

      <Spacer />

      <div className="om-forlover">
        <h2>Silje Helen Nilo</h2>
        <div className="bilde-wrapper">
          <img
            id="forlover-bilde"
            src={silje}
            alt="bilde av Silje"
            className="forlover-bilder"
          />
        </div>
        <p className="forlover-tekst">
          Hipsterstil og felles frustrasjon i første mattetime under
          bachelorstudiet i Kristiansand skulle vise seg å være starten på et
          godt vennskap. Det ble også tydelig at vi hadde felles interesse for
          1,5 flasker vin på vors, shots, mat og godteri. Senere har disse
          interessene også utviklet seg til å bli litt sunnere og aktive, med
          skiturer med hunden Tingeling i Østmarka.
        </p>
      </div>

      <Spacer />

      <div className="om-forlover">
        <h2>Sara Margareth Dahlen</h2>
        <div className="bilde-wrapper">
          <img
            id="forlover-bilde"
            src={sara}
            alt="bilde av Sara"
            className="forlover-bilder"
          />
        </div>
        <p className="forlover-tekst">
          Sara og Line ble venner første dag i Kristiansand da Sara skulle sitte
          på bagasjebrettet på sykkelen til Line hjem fra skolen. Uheldigvis var
          det gammel rust på bagasjebrettet så buksen til Sara var brukt for
          siste gang. Heldigvis var den felles kjærligheten for Sverige,
          Beyonce, morsomheter, lurerier, kaker, bakst og godteri større enn et
          par bukser.
        </p>
      </div>

      <Spacer />

      <div className="om-forlover">
        <h2>Philip Myre Aamlid</h2>
        <div className="bilde-wrapper">
          <img
            id="forlover-bilde"
            src={philip}
            alt="bilde av Philip"
            className="forlover-bilder"
          />
        </div>
        <p className="forlover-tekst">
          I år forsøkte Philip og Nicolai å gå tilbake og erindre hvordan de
          møttes. Dette selvfølgelig til ingen nytte, noe Line og Anne
          (kjæresten til Phil) på ingen måte ble overrasket over. Uansett, det
          var nok på starten av videregående, hvor vi ble trollbundet av Xboxen
          i kjelleren i Trettebakken. Mye helseskadelig mannelukt og
          latterkramper. Kallenavn oss i mellom er nurket eller fjusken. Line og
          Anne er sjeleglad for at disse får utløp for sine rastløsheter og
          overkoste kjærhet til hverandre, så slipper damene mas døgnet rundt.
        </p>
      </div>

      <Spacer />

      <div className="om-forlover" style={{ marginBottom: "5rem" }}>
        <h2>Hans Jacob Kavli</h2>
        <div className="bilde-wrapper">
          <img
            id="forlover-bilde"
            src={hansi}
            alt="bilde av Hansi"
            className="forlover-bilder"
          />
        </div>
        <p className="forlover-tekst">
          Nicolai og Hansi ble kjent på videregående hvor de delte sin
          forkjærlighet for de to beste tingene i livet. Lunsjkaker på Ica. Og
          råning. Ingenting er mer risikabelt enn 18 år gamle gutter på
          vinterdekk i Sørkedalen rett etter at lappen er i boks. Etter et
          opphold sammen i regi av den norske stat, militæret, bodde vi sammen i
          faren til Hansi sin leilighet i Neuberggata i 5 år. Matmengden til
          Hansi og Nicolai økte fortsatt dramatisk, men bil ble byttet ut med
          damer.
        </p>
      </div>
    </div>
  );
}

export default Forlovere;
