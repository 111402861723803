import React from "react";
import "./Omoss.css";
import nicolai from "./nicolai.jpg";
import line from "./line.PNG";
import motes from "./motes.jpg";

const Omoss = () => {
  return (
    <div className="omoss-site">
      <h1>
        Om oss{" "}
        <span role="img" aria-label="hammer emoji">
          👦
        </span>
        <span role="img" aria-label="hammer emoji">
          👧
        </span>
      </h1>
      <div className="om-person">
        <h2>Nicolai</h2>
        <div className="bilde-wrapper">
          <img
            id="nicolai-bilde"
            src={nicolai}
            alt="bilde av Nicolai"
            className="om-person-bilder"
          />
        </div>
        <p className="person-tekst">
          15. april 1991 ble Nicolai født på Aker sykehus, som lillebror og
          yngste medlem i familien Hagen (mor, far og storesøster Helene). I
          ungdomstiden levde han et bekymringsfritt liv på Røa med downhill,
          gangsterrap, litago og baconpølse.
        </p>
        <p>
          Fra ønsket om å bli Norges svar på Kanye ble realiteten en annen, og
          rap ble byttet ut med master i informatikk (IKT som vi andre sier).
          Han lærte mer enn bare å lage apper, og som en del av studiet var
          feltarbeid i Uganda på agendaen.{" "}
        </p>
        <p>
          Heldigvis har Nicolai hatt en ørkenrotte som het Rolf, og blir ofte et
          naturlig samtaletema når han snakker om seg selv.
        </p>
      </div>

      <div className="om-person">
        <h2>Line</h2>
        <div className="bilde-wrapper">
          <img
            id="line-bilde"
            src={line}
            alt="bilde av Line"
            className="om-person-bilder"
          />
        </div>
        <p className="person-tekst">
          22.september 1993 ble Line født og dette ble feiret, med samme bursdag
          som Märtha-Louise. Som første jente og deretter mellomste søsken i
          flokken (mor, far, bror Lars og søster Jenny), vokste hun opp på
          gården Fusk med håndball, 4H (ungdomsklubb lignende speideren), MSN og
          Habbo hotell.
        </p>
        <p>
          {" "}
          I mangel på universiteter i Askim flytter hun til Kristiansand for å
          studere økonomi. Viktig lærdom fra studiet var de tre P-er (Prosecco,
          prosent og pris) for å sikre en god fest torsdag, fredag og lørdag..
        </p>
        <p>
          Da hun innså at studielånet ikke var høyt nok, flyttet hun til Oslo og
          begynte på BI. Lite viste hun at det i løpet av master på BI også
          skulle lære hvilke franske viner som både er billig og berusende.
        </p>
      </div>

      <div className="motet-deres">
        <h2>
          Line og Nicolai møtes{" "}
          <span role="img" aria-label="hammer emoji">
            ❤️
          </span>
        </h2>
        <div className="bilde-wrapper">
          <img
            id="motes-bilde"
            src={motes}
            alt="bilde av møtet"
            className="om-person-bilder"
          />
        </div>
        <p className="motet-tekst">
          Januar 2017 var starten på vårt siste semester på studiet, og som
          januar ofte er, ble også denne januaren en forlengelse av juleferien.
          Hva skal man finne på når man er litt lei av julens festligheter, men
          også vil finne på noe gøy? Svaret for både Line og Nicolai ble Tinder.
        </p>
        <p>
          Det kan ofte være vanskelig å komme i prat med folk på internett, men
          heldigvis hadde Line, Oda, Hans Jacob og Andreas gjort det stort som
          kjendiser i Dagbladet samme dag som Line tilfeldigvis ga Nicolai en
          super-like på tinder (som Line den dag i dag mener var en
          feiltakelse). Kjendisstatusen måtte selvfølgelig feires og Nicolai så
          sitt snitt for å invitere Line med ut på en øl.{" "}
        </p>
        <p>
          Rotta Rolf og de tre B-er var hete samtaleemner og heldigvis ikke det
          som har definert vårt forhold. 31. januar var dagen for vår første
          date og allerede oktober samme år ble vi samboere på Adamstuen da
          Hans-Jacob sa klart i fra til Line at hun bare fikk være på besøk 3
          ganger uken (noe som er altfor mye i en sliten guttenbule vs fresh
          jente-vedlikeholdt leilighet på Kiellandsplass).{" "}
        </p>
        <p>
          Konstant gjennomtrekk i leiligheten på Adamstuen var for mye av det
          gode, og Toftes gate 23 aka "ACAB hill" ble en realitet. Glade dager
          med avslapning, langrenn, sykkel, alko, restaurant eller med venner
          nytes av begge, og nå. Skal vi gifte oss!!
        </p>
      </div>
    </div>
  );
};

export default Omoss;
