import React from "react";
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Gjesteliste from "../Gjesteliste";
import Forlovere from "../Forlovere";
import NicolineNavbar from "../NicolineNavbar";
import bryllupslogo from "./bryllupslogo_mobile_cropped.png";
import siste_bilde from "./bryllup_siste_bilde.JPG";
import RegistrerGjest from "../RegistrerGjest";
import Onskeliste from "../Onskeliste";
import Bryllupshelgen from "../Bryllupshelgen";
import Omoss from "../Omoss";
import Overnatting from "../Overnatting";
import Byggingen from "../Byggingen";
import Bidrag from "../Bidrag";
import Bordplassering from "../Bordplassering";
import Bildebank from "../Bildebank";

function Home() {
  const [secretRedirectCounter, setSecretRedirectCounter] = React.useState(0);

  return (
    <div className="hjem">
      <img
        id="hjem-bilde"
        onClick={(e) => setSecretRedirectCounter(secretRedirectCounter + 1)}
        src={bryllupslogo}
        alt="Logo"
      />
      <img
        id="nicoline-bilde"
        onClick={(e) => setSecretRedirectCounter(secretRedirectCounter + 1)}
        src={siste_bilde}
        alt="bilde av Line og Nicolai på festival"
      />
      <h1>
        Takk for en tidenes helg{" "}
        <span role="img" aria-label="hammer emoji">
          💛
        </span>
      </h1>

      {/*  <div>
        <Button
          type="submit"
          onClick={() => document.getElementById("getFile").click()}
          style={{
            marginTop: "2rem",
            width: "75%",
            backgroundColor: "#0d9dfd",
          }}
        >
          Last opp {bildeLastetOpp && "et nytt "} ditt blinkskudd!{" "}
        </Button>

        {bildeLastetOpp && (
          <h3 style={{ marginTop: "2rem" }}>
            {percent < 100 && (
              <>
                <span>Bildet ditt laster opp</span>
                <span role="img" aria-label="hammer emoji">
                  👌
                </span>{" "}
                {` (${percent}%) `}
              </>
            )}
            {percent === 100 && (
              <>
                <span>Vi har bildet ditt!</span>
                <span role="img" aria-label="hammer emoji">
                  💯
                </span>
              </>
            )}
          </h3>
        )}

        <Link
          to="/bordplassering"
          onClick={() => {
            window.scrollTo({ top: 0 });
          }}
        >
          <Button
            type="submit"
            style={{
              marginTop: "2rem",
              width: "75%",
              backgroundColor: "#f44a67",
            }}
          >
            Finn din bordplassering!
          </Button>
        </Link>

        <input
          type="file"
          id="getFile"
          onChange={handleChange}
          accept="/image/*"
          style={{ display: "none" }}
        />
      </div> */}
      {secretRedirectCounter >= 3 && <Navigate to="/gjesteliste" />}
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <div>
        <NicolineNavbar />
        <Routes>
          <Route path="gjesteliste" element={<Gjesteliste />} />
          <Route path="forlovere" element={<Forlovere />} />
          <Route path="overnatting" element={<Overnatting />} />
          <Route path="bidrag" element={<Bidrag />} />
          <Route path="bordplassering" element={<Bordplassering />} />
          <Route path="bilder" element={<Bildebank />} />
          <Route path="onskeliste" element={<Onskeliste />} />
          <Route path="bryllupshelgen" element={<Bryllupshelgen />} />
          <Route path="omoss" element={<Omoss />} />
          <Route path="registrer" element={<RegistrerGjest />} />
          <Route path="byggingen" element={<Byggingen />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
