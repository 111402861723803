import React from "react";
import "./Onskeliste.css";

import kitchen from "./kitchen.png";
import japan from "./japan.jpg";

const Onskeliste = () => {
  return (
    <div className="onskeliste">
      <h1>
        Ønskeliste
        <span role="img" aria-label="hammer emoji">
          🎁
        </span>
      </h1>

      <div className="onske" style={{ textAlign: "left", padding: "4rem" }}>
        <i>
          Det vi ønsker oss aller, aller mest er at dere kommer i bryllupet og
          feirer med oss, men dersom dere ønsker å gi gave har vi satt sammen
          noen ønsker under.
        </i>
      </div>

      <div className="onske">
        <div style={{}}>
          <span role="img" aria-label="venner" style={{ fontSize: "3rem" }}>
            👪
          </span>
        </div>
        <div style={{ padding: "1rem" }}>
          <p>
            Vi er glad i morsomme opplevelser, og ønsker oss å oppleve noe gøy
            og/eller hyggelig sammen med dere!
          </p>
        </div>
      </div>

      <div className="onske">
        <img className="onske-bilde" src={kitchen} alt="Kitchen logo" />
        <div className="onske-tekst">
          <a
            href="https://www.kitchn.no/onskeliste/134868"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gaveliste på Kitchen
          </a>
        </div>
      </div>

      <div className="onske">
        <img
          className="onske-bilde"
          src={japan}
          alt="Bilde av blomstring i Japan"
        />
        <div className="onske-tekst">
          Bidrag til å finne på sprell under vår planlagte Japan-tur. Vårt kto.
          er <strong>xxxx.xx.xxxxx</strong>
        </div>
      </div>
    </div>
  );
};

export default Onskeliste;
