import React, { useState, useEffect, useRef } from "react";
import { addDoc, collection } from "firebase/firestore"; // import the methods from firestore
import { firestoreDb } from "../config/firebase";
import "./RegistrerGjest.css";
import { Button } from "react-bootstrap";
import LineogNicoBilde from "./lineognicolai.PNG";

async function addToGjesteliste(
  navn = "Nicolai 9co Hagen",
  telefonnummer = "92696046",
  kommer_fredag = "yes",
  kommer_lordag = "yes",
  allergier = "nej",
  favorittsang
) {
  await addDoc(collection(firestoreDb, "gjesteliste"), {
    Navn: navn,
    Telefonnummer: telefonnummer,
    "Kommer fredag": kommer_fredag,
    "Kommer lørdag": kommer_lordag,
    Allergier: allergier,
    Favorittsang: favorittsang,
  });
}

const NicoLineRoterendeBilde = () => (
  <img
    src={LineogNicoBilde}
    alt="Nicolai og Line spinner"
    className="nicolinebilde"
  />
);

const RegistrerGjest = () => {
  const [isLoading, setLoading] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [navn, setNavn] = useState("");
  const [telefonnummer, setTelefonnummer] = useState("");
  const [kommer_fredag, setKommerFredag] = useState("");
  const [kommer_lordag, setKommerLordag] = useState("");
  const [allergier, setAllergier] = useState("");
  const [favorittsang, setFavorittsang] = useState("");

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    if (isLoading) {
      addToGjesteliste(
        navn,
        telefonnummer,
        kommer_fredag,
        kommer_lordag,
        allergier,
        favorittsang
      ).then(() => {
        setLoading(false);
        setHasSent(true);
        executeScroll();
      });
    }
  }, [
    isLoading,
    navn,
    telefonnummer,
    kommer_fredag,
    kommer_lordag,
    allergier,
    favorittsang,
  ]);

  const ALLE_ER_PAMELDT = true;

  if (ALLE_ER_PAMELDT) {
    return (
      <div
        className="register-page"
        style={{ width: "80%", marginTop: "8rem" }}
      >
        <h1>
          Alle vi har invitert har nå meldt seg på. Vi gleder oss!{" "}
          <span role="img" aria-label="hammer emoji">
            💌
          </span>
        </h1>
      </div>
    );
  }

  return (
    <div className="register-page">
      <div>
        <h1>
          Meld din egen ankomst{" "}
          <span role="img" aria-label="hammer emoji">
            💌
          </span>
        </h1>
        <form className="nicoline-form" onClick={() => setHasSent(false)}>
          <div className="form-group form-gruppa">
            <label htmlFor="navnplaceholder" className="marginated bolded">
              Navn
            </label>
            <input
              type="text"
              onChange={(e) => setNavn(e.target.value)}
              className="form-control marginated"
              id="navnplaceholder"
              placeholder="Ditt navn"
            />
          </div>
          <div className="form-group form-gruppa">
            <label htmlFor="telefonplaceholder" className="marginated bolded">
              Telefonnummer
            </label>
            <input
              type="phone"
              onChange={(e) => setTelefonnummer(e.target.value)}
              className="form-control marginated"
              id="telefonplaceholder"
              placeholder="xxx xx xxx"
            ></input>
          </div>
          <div className="form-group form-gruppa">
            <label
              htmlFor="kommerfredagplaceholder"
              className="marginated bolded"
            >
              Kommer fredagen
            </label>
            <input
              type="text"
              onChange={(e) => setKommerFredag(e.target.value)}
              className="form-control marginated"
              id="kommerfredagplaceholder"
              placeholder="ja / nei"
            ></input>
          </div>
          <div className="form-group form-gruppa">
            <label
              htmlFor="kommerlordagplaceholder"
              className="marginated bolded"
            >
              Kommer lørdagen
            </label>
            <input
              type="text"
              onChange={(e) => setKommerLordag(e.target.value)}
              className="form-control marginated"
              id="kommerlordagenplaceholder"
              placeholder="ja / nei"
            ></input>
          </div>
          <div className="form-group form-gruppa">
            <label htmlFor="allergiplaceholder" className="marginated bolded">
              Allergier (valgfri)
            </label>
            <input
              type="string"
              onChange={(e) => setAllergier(e.target.value)}
              className="form-control marginated"
              id="allergiplaceholder"
              placeholder="Valnøtter, dårlig stemning...etc.."
            />
          </div>
          <div className="form-group form-gruppa">
            <label htmlFor="sangplaceholder" className="marginated bolded">
              Favoritt partysang (valgfri)
            </label>
            <input
              type="text"
              onChange={(e) => setFavorittsang(e.target.value)}
              className="form-control marginated"
              id="sangplaceholder"
              placeholder="Din favorittsang"
            />
          </div>
        </form>
        {hasSent && (
          <div className="has-sent" ref={myRef}>
            <div style={{ textAlign: "center" }}>
              <NicoLineRoterendeBilde />
            </div>
            <div className="registrert">
              {navn && navn + ", "}du er registrert. Vi gleder oss til du
              kommer!{" "}
              <span role="img" aria-label="tada emoji">
                🎉
              </span>
            </div>
          </div>
        )}
        <div className="registrer-btn">
          <Button
            type="submit"
            value="Send inn"
            className="marginated"
            disabled={isLoading}
            onClick={!isLoading ? () => setLoading(true) : null}
          >
            {isLoading ? "Legger inn..." : "Send inn!"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegistrerGjest;
