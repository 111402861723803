import React from "react";
import "./Bryllupshelgen.css";
import fusk from "./fusk.jpg";
import brodremoen from "./brodremoen.jpg";
import parkering from "./parkering.png";
import { Accordion } from "react-bootstrap";

const Spacer = () => (
  <div style={{ textAlign: "center", fontSize: "50px" }}>⋮</div>
);

function Bryllupshelgen() {
  return (
    <div className="helg-page">
      <h1>
        Bryllupshelgen{" "}
        <span role="img" aria-label="hammer emoji">
          👰
        </span>
        <span role="img" aria-label="hammer emoji">
          🤵
        </span>
      </h1>
      <div className="helg-intro">
        <p>
          Første helgen i august inviterer vi til gårdsbryllupshelg i Indre
          Østfold ("Indre" eller "IØ" hvis du vil sikre å gli rett inn i
          lokalmiljøet). Bryllupshelgen foregår på familiegårdene Fusk og
          Brødremoen, som er 5km fra hverandre.
        </p>
      </div>

      <div className="dag-intro">
        <h2>Fredagen (5. august)</h2>
        <p style={{ marginTop: "1rem" }}>
          For å varme ordentlig opp og samtidig roe ned nervene til bryllupet,
          vil vi gjerne invitere til grilling med dere fredagen. Lars, broren
          til Line, har ordnet med leker for anledningen. Vi stiller med
          grillmat og drikke (også vegetar, glutenfritt og alkoholfritt).
          Antrekket er helt valgfritt sommerlig, men vi anbefaler sko som tåler
          å gå på gress/grus og klær til det blir litt kaldere på kvelden.
        </p>
        <p className="dag-intro-tekst">
          <strong>Adresse </strong>
        </p>
        <p style={{ margin: "0" }}>Fusk gård</p>
        <p> Askimveien 625, 1814 Askim</p>
        <p className="dag-intro-tekst">
          <strong>Klokkeslett</strong>
        </p>
        <p>kl. 17 (lekene begynner kl 18:00)</p>
        <div>
          <img src={fusk} alt="bilde av gården Fusk" className="gard-bilde" />
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey={1} key={1} className="gard-accordion">
            <Accordion.Header className="gard-accordion">
              Om gården Fusk
            </Accordion.Header>
            <Accordion.Body className="gard-accordion">
              <p>
                Gården ligger lengst øst i bygda, på sletta som fortsetter
                innover i Eidsberg. Navnet kommer fra gno, fauskr, som betyr
                forvitret tre. Den gamle uttalelsen er Føsk. Men Føsk har de
                senere årene blitt forvrengt av Fusk. Navnet ble skrevet Fousch
                i 1593, Fusk i 1838 og Føsk i 1886, men da med merknad at navnet
                vanligvis ble skrevet Fusk.
              </p>
              <p>
                Senere, i 1940, ble gården beskutt under krigshandlingene.
                Norske styrker hadde maskingeværstillinger på fjellet ved vestre
                låvebrua. En del av buskapen (husdyr) strøk med. Låven har enda
                synlige spor etter krigshandlingene.
              </p>
              <p>
                Lines besteforeldre flyttet til gården i 1970 og i 1995 tok
                Lines far over driften av gården. Om du er mer nysgjerrig på
                gården er der bare å spørre brudens far, Ola Brevig. P.S. Dette
                er hans favoritt tema, så husk at om du legger på en krone så
                går skravla.{" "}
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <Spacer />

      <div className="dag-intro" style={{ marginBottom: "3rem" }}>
        <h2>Lørdagen (6. august)</h2>
        <p style={{ marginTop: "1rem" }}>
          Selve bryllupet finner sted lørdag 6.august 2022 på Brødremoen gård.
          Vielsen vil være på gården med ordfører Saxe Frøshaug som vigsler og
          vielsen starter kl 14.00. Deretter feirer vi ved å fylle opp glassene,
          sette en fot foran den andre og gi et vanvittig liv til en låve som er
          gjort om til festlokale for anledningen. YEAH!{" "}
          <span role="img" aria-label="hammer emoji">
            🕺
          </span>
          <span role="img" aria-label="hammer emoji">
            💃
          </span>
        </p>
        <p className="dag-intro-tekst">
          <strong>Adresse </strong>
        </p>
        <p style={{ margin: "0" }}>Brødremoen gård</p>
        <p> Veslemona 120, 1850 Mysen</p>
        <p className="dag-intro-tekst">
          <strong>Klokkeslett </strong>
        </p>
        <p style={{ marginBottom: "0.25rem" }}>kl. 13.30: Vi møtes på gården</p>
        <p style={{ marginBottom: "0.25rem" }}>kl. 14.00: Vielsen starter</p>
        <p style={{ marginBottom: "0.25rem" }}>
          kl. 15.00: Enkel mat og fotografering
        </p>
        <p>kl. 16.30: Middag og fest!</p>
        <p className="dag-intro-tekst">
          <strong>Antrekk </strong>
        </p>
        <p>Mørk dress / smoking.</p>
        <p>
          OBS! Vi anbefaler skotøy som passer bra med låvegulv som har blitt
          formet av tidens tann. Spar stiletthælene{" "}
          <span role="img" aria-label="hammer emoji">
            👠
          </span>
        </p>

        <p className="dag-intro-tekst">
          <strong>Parkering </strong>
        </p>

        <p>
          De som ønsker å kjøre bil må gjerne gjøre det. Vi har ordnet med
          parkering 2 ulike steder i nærheten for å unngå biler på gårdstunet
          under bryllupet.
        </p>

        <p>
          <b>
            <a href="https://goo.gl/maps/Tq5QZorzd5UXFa6S8">
              Parkeringsplass 1 - 50 meter unna.
            </a>
          </b>
          <p>
            Rett nedenfor gården på gress. Vi har rensket så godt vi kan, men
            vær obs. på stubber o.l.
          </p>
        </p>

        <p>
          <b>
            <a
              href="https://goo.gl/maps/pfZS2TmeNT9KVzy89"
              style={{ marginTop: "1rem" }}
            >
              Parkeringsplass 2 - 300 meter unna
            </a>
          </b>
          <p>
            Inne på parkeringsplassen til bedriften Mona Mek AS. Vi har fått
            dispensasjon til å stå her lørdagen.{" "}
          </p>
        </p>

        <div
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            margin: "2rem auto",
          }}
        >
          <img
            src={parkering}
            alt="bilde av gården Brødremoen"
            className="gard-bilde"
          />
        </div>

        <div>
          <img
            src={brodremoen}
            alt="bilde av gården Brødremoen"
            className="gard-bilde"
          />
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey={1} key={1} className="gard-accordion">
            <Accordion.Header className="gard-accordion">
              Om gården Brødremoen
            </Accordion.Header>
            <Accordion.Body className="gard-accordion">
              <p>
                Brødremoen gård er gården hvor moren til Line og hennes søsken
                er vokst opp, sammen mor og far Kirsten og Ole Kristian. Gården
                hadde for rundt 50 år siden mange ulike dyr i fjøset under
                låven, sammen med kornproduksjon.
              </p>
              <p>
                I dag brukes låven av Ola og Lars, brudens far og bror, til
                blant annet korntørking. Ukene rundt bryllupsdatoen er midt i
                innhøstingssesongen til årets korn, så kornet vil være enten
                nylig høstet eller veldig nærme innhøsting.{" "}
                <span role="img" aria-label="hammer emoji">
                  🌾
                </span>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default Bryllupshelgen;
