import React, { useState } from "react";
import { Fade as Hamburger } from "hamburger-react";
import "./NicolineNavbar.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import home from "./home.png";

function NicolineNavbar() {
  const [isOpen, setOpen] = useState(false);
  const HOME_PATH = "/";
  const location = useLocation();

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);

    setOpen(false);
  };

  return (
    <div>
      <div className="nicoline-hamburger">
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      {isOpen && (
        <ul className="meny">
          <Link
            to="/bryllupshelgen"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Bryllupshelgen / praktisk</li>
          </Link>
          {
            <Link
              to="/bordplassering"
              onClick={() => {
                setOpen(false);
                window.scrollTo({ top: 0 });
              }}
            >
              <li>Bordplassering</li>
            </Link>
          }
          <Link
            to="/onskeliste"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Ønskeliste</li>
          </Link>
          <Link
            to="/bidrag"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Taler eller bidrag?</li>
          </Link>
          <Link
            to="/byggingen"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Vi bygger om låven!</li>
          </Link>
          <Link
            to="/omoss"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Om oss</li>
          </Link>
          <Link
            to="/forlovere"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Forlovere</li>
          </Link>
          <Link
            to="/overnatting"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Overnatting</li>
          </Link>
          <Link
            to="/registrer"
            onClick={() => {
              setOpen(false);
              window.scrollTo({ top: 0 });
            }}
          >
            <li>Meld din ankomst</li>
          </Link>
          
        </ul>
      )}
      {HOME_PATH !== location.pathname && (
        <img
          src={home}
          alt="bilde av hjem"
          className="hjemknapp"
          onClick={routeChange}
        />
      )}
    </div>
  );
}

export default NicolineNavbar;
